<template>
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.8667 3.76641L32.7167 9.61641C33.9833 10.2997 33.9833 12.2497 32.7167 12.9331L21.8667 18.7831C20.9 19.2997 19.7667 19.2997 18.8 18.7831L7.95 12.9331C6.68333 12.2497 6.68333 10.2997 7.95 9.61641L18.8 3.76641C19.7667 3.24974 20.9 3.24974 21.8667 3.76641Z" stroke="#1F3330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.34935 16.8833L16.4327 21.9333C17.6827 22.5667 18.4827 23.85 18.4827 25.25V34.7833C18.4827 36.1667 17.0327 37.05 15.7993 36.4333L5.71602 31.3833C4.46602 30.75 3.66602 29.4667 3.66602 28.0667V18.5333C3.66602 17.15 5.11602 16.2667 6.34935 16.8833Z" stroke="#1F3330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34.316 16.8833L24.2326 21.9333C22.9826 22.5667 22.1826 23.85 22.1826 25.25V34.7833C22.1826 36.1667 23.6326 37.05 24.866 36.4333L34.9493 31.3833C36.1993 30.75 36.9993 29.4667 36.9993 28.0667V18.5333C36.9993 17.15 35.5493 16.2667 34.316 16.8833Z" stroke="#1F3330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CubeIcon',
};
</script>
