<template>
  <div class="latest-news-grid-carousel">
    <NewsCard v-for="(item, i) in latestNews" :key="i" :item="item" width="316px"/>
  </div>
</template>
<script>
import NewsCard from '@/components/NewsCard.vue';

export default {
  name: 'NewsCarouselGrid',
  components: { NewsCard },
  props: {
    latestNews: {},
  },
};
</script>
<style scoped lang="scss">
.latest-news-grid-carousel {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-gap: 12px;
  grid-auto-flow: column;
  grid-auto-columns: auto;
}
</style>
