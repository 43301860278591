<template>
  <v-footer :dark="!isThemeLight" :color="isThemeLight ? 'background' : 'primary'" class="footer--border" :class="footerClass">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" xl="3">
          <v-img
            contain
            width="160px"
            :src="isThemeLight
                ? require('../../assets/img/logo/logo-light.svg')
              : require('../../assets/img/logo/logo.svg')"
          />

          <div>
            <div class="mt-6 opacity-semi footer-text--color hidden-lg-and-up">Assist Bridging Ltd.<br/>
              Chiswick Gate, 598-608 Chiswick High Road,<br/>
              London, W4 5RT
            </div>
            <div class="mt-8 opacity-semi footer-text--color hidden-md-and-down">Assist Bridging Ltd.<br/>
              Chiswick Gate,<br/>
              598-608 Chiswick High Road,<br/>
              London, W4 5RT
            </div>
          </div>

        </v-col>

        <v-col cols="12" class="hidden-xl-only">
          <v-divider class="mt-10 mb-5 footer-divider"/>
        </v-col>

        <v-col
          v-for="(itemList, i) in footerList"
          :key="i"
          cols="12"
          md="6"
          xl="3"
        >
          <v-list color="transparent" class="mx-n4">
            <v-list-item
              v-for="(item, i) in itemList"
              :key="i"
              class="v-list-item--selectable"
            >
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  <div v-if="i === 0 || item.isTitle" class="footer-text--color text-body-3 font-weight-bold mt-1">
                    {{ item.title }}
                  </div>
                  <div v-else-if="item.type !== 'internal'" class="footer-text--color">
                    <a
                      class="link--no-decoration text-body-3"
                      :href="item.href"
                      target="_blank"
                    >{{ item.title }}</a>
                  </div>
                  <div v-else class="footer-text--color">
                    <router-link class="link--no-decoration text-body-3" :to="item.href">
                      {{ item.title }}
                    </router-link>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12">
          <v-divider class="mt-3 mb-6 my-xl-8 footer-divider"/>
        </v-col>

        <v-col cols="12" class="text-body-3 mt-0 mb-n1 footer-text--color">
          <div>
            <v-icon class="footer-text--color">mdi-copyright</v-icon>
            Copyright {{ new Date().getFullYear() }}. Assist Bridging Ltd.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>

export default {
  name: 'AppFooter',
  data() {
    return {
      contactList: [
        [
          {
            title: 'Call us',
            href: '#',
          },
          {
            title: '+44 792 716 4563',
            href: 'tel:+447927164563',
          },
        ],
        [
          {
            title: 'Email us',
            href: '#',
          },
          {
            title: 'info@assistbridging.com',
            href: 'mailto:info@assistbridging.com',
          },
        ],
      ],
      footerListMobile: [
        [
          {
            title: 'Useful links',
            href: '#',
          },
          {
            title: 'About us',
            type: 'internal',
            href: { name: 'about-us' },
          },
          {
            title: 'Calculate Your Loan',
            type: 'internal',
            href: 'calculate-your-loan',
          },
          {
            title: 'Blog',
            type: 'internal',
            href: { name: 'blog' },
          },
        ],
        [
          {
            title: 'Legal issues',
            href: '#',
          },
          {
            title: 'Terms & Conditions',
            href: '/files/terms-and-conditions.pdf',
            type: 'external',
          },
          {
            title: 'Privacy Notice (GDPR)',
            href: '/files/privacy-policy.pdf',
            type: 'external',
          },
          {
            title: 'Complaints Policy',
            href: '/files/complaints-policy.pdf',
            type: 'external',
          },
        ],
        [
          {
            title: 'Call us',
            href: '#',
          },
          {
            title: '+44 792 716 4563',
            href: 'tel:+447927164563',
          },
        ],
        [
          {
            title: 'Email us',
            href: '#',
          },
          {
            title: 'info@assistbridging.com',
            href: 'mailto:info@assistbridging.com',
          },
        ],
      ],
      footerListDesktop: [
        [
          {
            title: 'Useful links',
            href: '#',
          },
          {
            title: 'About us',
            type: 'internal',
            href: { name: 'about-us' },
          },
          {
            title: 'Calculate Your Loan',
            type: 'internal',
            href: '/calculate-your-loan',
          },
          {
            title: 'Blog',
            type: 'internal',
            href: { name: 'blog' },
          },
        ],
        [
          {
            title: 'Legal issues',
            href: '#',
          },
          {
            title: 'Terms & Conditions',
            href: '/files/terms-and-conditions.pdf',
            type: 'external',
          },
          {
            title: 'Privacy Notice (GDPR)',
            href: '/files/privacy-policy.pdf',
            type: 'external',
          },
          {
            title: 'Complaints Policy',
            href: '/files/complaints-policy.pdf',
            type: 'external',
          },
        ],
        [
          {
            title: 'Call us',
            href: '#',
          },
          {
            title: '+44 792 716 4563',
            href: 'tel:+447927164563',
          },
          {
            title: 'Email us',
            isTitle: true,
            href: '#',
          },
          {
            title: 'info@assistbridging.com',
            href: 'mailto:info@assistbridging.com',
          },
        ],
      ],
    };
  },
  computed: {
    isAboutUs() {
      return (this.$route.name || '').split('.')[1] === 'about';
    },
    isHome() {
      return (this.$route.name || '') === 'home';
    },
    footerClass() {
      return {
        'footer--extend': this.$route.meta.footerExtend,
      };
    },
    isThemeLight() {
      return !!this.$route.meta.footerLight;
    },
    footerList() {
      return this.$vuetify.breakpoint.mdAndDown
        ? this.footerListMobile
        : this.footerListDesktop;
    },
  },
};
</script>
<style lang="scss" scoped>
footer {
  // @todo Adjust margin top
  padding-top: 24px;
  padding-bottom: 12px;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-top: 80px;
    padding-bottom: 23px;
  }
}

.footer--extend {
  padding-top: 96px;

  @media #{map-get($display-breakpoints, 'md-and-up')}{
    padding-top: 170px;
  }
}
.v-footer {
  padding-left: 0;
  padding-right: 0;
}

.footer-social-header {
  font-family: $heading-font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    font-size: 24px;
    line-height: 30px;
  }
}
.footer-company-info {
  font-size: 14px;
  line-height: 21px;
}

.footer__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

.footer-subtitle {
  margin-top: 24px;
}
.v-list-item--selectable {
  min-height: 24px !important;
  margin-bottom: 9px;

  .v-list-item__content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.theme--dark.footer-text--color {
  color: white;
}
.theme--dark.footer--border {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.theme--dark.footer-divider {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.theme--light.footer--border {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.theme--light.footer-divider {
  border: 1px solid rgba(31, 51, 48, 0.1) !important;
}

.theme--light.footer-text--color {
  color: var(--v-primary-base);
}
</style>
