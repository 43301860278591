<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.92593 8.83377C9.30041 6.36623 8.39506 3.42165 7.20988 0H12.7901C11.6379 3.32294 10.716 6.26753 10.0247 8.83377H9.92593ZM9.23457 9.42597C7.42387 9.32727 5.66255 9.27792 3.95062 9.27792C2.23868 9.27792 0.921811 9.29437 0 9.32727L1.67901 3.9974C4.70782 6.26753 7.2428 8.04416 9.28395 9.32727L9.23457 9.42597ZM10.716 9.32727C13.0864 7.81385 15.6049 6.03723 18.2716 3.9974L20 9.32727C19.0453 9.29437 17.7284 9.27792 16.0494 9.27792C14.3374 9.27792 12.5761 9.32727 10.7654 9.42597L10.716 9.32727ZM2.12346 15.7429C5.48148 13.3411 7.95062 11.4987 9.53086 10.2156L9.58025 10.2649C8.69136 12.5022 7.7037 15.4139 6.61728 19L2.12346 15.7429ZM13.3827 19C12.2963 15.4139 11.3086 12.5022 10.4198 10.2649L10.4691 10.2156C12.0494 11.4987 14.5185 13.3411 17.8765 15.7429L13.3827 19Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'StartIcon',
};
</script>
