<template>
  <div class="latest-news-grid">
    <div v-for="(item, i) in sortedNews"
         :key="i"
         style="grid-column:auto / span 2"
    >
      <NewsCard :key="i" :item="item"/>
    </div>
    <slot name="action"></slot>
  </div>
</template>
<script>
import NewsCard from '@/components/NewsCard.vue';

export default {
  name: 'NewsGrid',
  components: { NewsCard },
  props: {
    latestNews: [],
  },
  computed: {
    sortedNews() {
      return this.latestNews.sort((a, b) => (a.id < b.id) ? 1 : -1);
    },
  },
};
</script>
<style lang="scss" scoped>
.latest-news-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 24px;
}
</style>
