<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.2666 10.2833C31.5999 12.6 33.8999 16.2833 34.3666 20.5333" stroke="#1F3330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.81641 20.6162C6.24974 16.3829 8.51641 12.6995 11.8164 10.3662" stroke="#1F3330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.6504 34.9004C15.5837 35.8837 17.7837 36.4337 20.1004 36.4337C22.3337 36.4337 24.4337 35.9337 26.3171 35.0171" stroke="#1F3330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.1001 12.8331C22.6591 12.8331 24.7335 10.7587 24.7335 8.19974C24.7335 5.64082 22.6591 3.56641 20.1001 3.56641C17.5412 3.56641 15.4668 5.64082 15.4668 8.19974C15.4668 10.7587 17.5412 12.8331 20.1001 12.8331Z" stroke="#1F3330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.05033 33.2003C10.6092 33.2003 12.6837 31.1258 12.6837 28.5669C12.6837 26.008 10.6092 23.9336 8.05033 23.9336C5.49141 23.9336 3.41699 26.008 3.41699 28.5669C3.41699 31.1258 5.49141 33.2003 8.05033 33.2003Z" stroke="#1F3330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M31.9497 33.2003C34.5087 33.2003 36.5831 31.1258 36.5831 28.5669C36.5831 26.008 34.5087 23.9336 31.9497 23.9336C29.3908 23.9336 27.3164 26.008 27.3164 28.5669C27.3164 31.1258 29.3908 33.2003 31.9497 33.2003Z" stroke="#1F3330" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'ShareIcon',
};
</script>
