<template>
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 1.00029C22.0424 1.67577 20.9821 2.1924 19.86 2.53029C19.2577 1.8378 18.4573 1.34698 17.567 1.12422C16.6767 0.90145 15.7395 0.957486 14.8821 1.28474C14.0247 1.612 13.2884 2.19469 12.773 2.95401C12.2575 3.71332 11.9877 4.61263 12 5.53029V6.53029C10.2426 6.57586 8.50127 6.1861 6.93101 5.39574C5.36074 4.60537 4.01032 3.43893 3 2.00029C3 2.00029 -1 11.0003 8 15.0003C5.94053 16.3983 3.48716 17.0992 1 17.0003C10 22.0003 21 17.0003 21 5.50029C20.9991 5.22174 20.9723 4.94388 20.92 4.67029C21.9406 3.66378 22.6608 2.393 23 1.00029V1.00029Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'TwitterIcon',
};
</script>
