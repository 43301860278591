<template>
  <div class="hero" style="z-index:-100">
    <v-container
      fill-height
      class="container-wrapper align-end"
    >
      <v-row
        class="white--text"
      >
        <v-col cols="12" lg="6">
          <v-flex class="flex-column">
            <v-row no-gutters>
              <div class="text-h4 text-md-h1 white--text" style="width: 100%">
                Delivering seamless lending solutions
              </div>
              <div class="hero-subtitle semi-white--text mt-3 mt-md-6 text-h6">
                Secure the bridge loan you need to overcome that final gap to future success.
              </div>
            </v-row>
            <v-row no-gutters>

              <router-link
                text
                class="white--text link--no-decoration text-body-3 text-md-body-1 font-weight-bold text-underline mt-5 mt-md-8"
                to="calculate-your-loan"
              >
                Calculate your loan
              </router-link>
            </v-row>
          </v-flex>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
//@import '~vuetify/src/styles/styles.sass';

.hero {
  width:100%;
  margin-top: -86px;
  padding-top: 86px;
  background: linear-gradient(180deg, rgba(31, 51, 48, 0.75) 0%, rgba(31, 51, 48, 0.75) 100%), url('@/assets/img/hero.png') center;
  background-size: cover;
  height: 480px;

  padding-bottom:32px;

  @media #{map-get($display-breakpoints, 'md-and-up')}{
    background: linear-gradient(180deg, rgba(31, 51, 48, 0.75) 0%, rgba(31, 51, 48, 0.75) 100%), url('@/assets/img/hero.png') center;
    background-size: cover;
    height: 900px;
    margin-top: -130px;
    padding-top: 0;
    padding-bottom:96px;
  }
  /*object-fit: contain;*/

  //height:100vh;
}

.hero-subtitle {
  width: 486px;
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 12px;
}
</style>
