<template>
  <v-expand-transition>
    <v-sheet width="100%" :style="styleObj" class="d-flex flex-column justify-space-between" :color="color">
      <slot></slot>
    </v-sheet>
  </v-expand-transition>
</template>

<script>
export default {
  name: 'ExpandNavbar',
  props: {
    headerHeight: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    styleObj() {
      return {
        height: '100vh',
        top: `${this.headerHeight}px`,
        'max-height': `calc(100% - ${this.headerHeight}px)`,
        'overflow-y': 'auto',
        'overflow-x': 'hidden',
        position: 'fixed',
        'z-index': 4,
      };
    },
  },

};
</script>

<style scoped>

</style>

<!--A clipped drawer rests under the application toolbar. Note: requires the clipped-left or clipped-right prop on v-app-bar to work as intended-->
<!-- @todo Use app properties like v-drawer rather headerHeight props, verify z-index usage -->
