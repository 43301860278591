<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
/* Local modules */
import DefaultLayout from '@/layout/Default.vue';
import BlankLayout from '@/layout/Blank.vue';

export default {
  name: 'App',
  components: {
    DefaultLayout,
    BlankLayout,
  },
  watch: {
    '$vuetify.breakpoint.name': {
      immediate: true,
      handler(newValue, oldValue) {
        console.log('Breakpoint:', this.$vuetify.breakpoint.name);
      },
    },
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || 'default'}-layout`;
    },
  },
};
</script>
