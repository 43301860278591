import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/lib/services/goto';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'home',
    component: HomeView,
    meta: {
      isHome: true,
      footerExtend: true,
    },
  },
  {
    path: '/product/buy-to-let-mortgage',
    name: 'product-buy-to-let-mortgage',
    component: () => import(/* webpackChunkName: "product-buy-to-let-mortgage" */ '../views/ProductBuyToLetMortgageView.vue'),
    meta: {
      footerExtend: true,
    },
  },
  {
    path: '/product/residental-investments',
    name: 'product-residental-investments',
    component: () => import(/* webpackChunkName: "product-residental-investments" */ '../views/ProductResidentalInvestmentsView.vue'),
    meta: {
      footerExtend: true,
    },
  },

  {
    path: '/product/development-bridging-loans',
    name: 'product-development-bridging-loans',
    component: () => import(/* webpackChunkName: "product-development-bridging-loans" */ '../views/ProductDevelopmentBridgingLoansView.vue'),
    meta: {
      footerExtend: true,
    },
  },
  {
    path: '/product/commercial-bridging-loans',
    name: 'product-commercial-bridging-loans',
    component: () => import(/* webpackChunkName: "product-commercial-bridging-loans" */ '../views/ProductCommercialBridgingLoansView.vue'),
    meta: {
      footerExtend: true,
    },
  },
  {
    path: '/product/complex-bridging-loans',
    name: 'product-complex-bridging-loans',
    component: () => import(/* webpackChunkName: "product-complex-bridging-loans" */ '../views/ProductComplexBridgingLoansView.vue'),
    meta: {
      footerExtend: true,
    },
  },
  {
    path: '/calculate-your-loan',
    name: 'calculate-your-loan',
    component: () => import(/* webpackChunkName: "calculate-your-loan" */ '../views/CalculateYourLoanView.vue'),
    meta: {
      footerLight: true,
    },
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/AboutUsView.vue'),
    meta: {
      footerExtend: true,
      footerLight: true,
    },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUsView.vue'),
  },

  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog/BlogIndexView.vue'),
    meta: {
      footerExtend: true,
      footerLight: false,
    },
  },
  {
    path: '/blog/bridging-the-gap',
    name: 'blog-bridging-the-gap',
    component: () => import(/* webpackChunkName: "blog-bridging-the-gap" */ '../views/Blog/BlogPostBridgingTheGapView.vue'),
    meta: {
      footerExtend: true,
      footerLight: true,
      headerDark: true,
    },
  },
  {
    path: '/blog/five-essential-tips-for-finding-the-right-bridging-finance-provider',
    name: 'blog-five-essential-tips-for-finding-the-right-bridging-finance-provider',
    component: () => import(/* webpackChunkName: "blog-five-essential-tips-for-finding-the-right-bridging-finance-provider" */ '../views/Blog/BlogPostFiveEssentialTipsForFindingTheRightBridgingFincanceProviderView.vue'),
    meta: {
      footerExtend: true,
      footerLight: true,
      headerDark: true,
    },
  },
  {
    path: '/blog/top-bridging-loan-trends-for-2023',
    name: 'blog-top-bridging-loan-trends-for-2023',
    component: () => import(/* webpackChunkName: "blog-top-bridging-loan-trends-for-2023" */ '../views/Blog/BlogPostTopBridgingLoanTrendsFor2023View.vue'),
    meta: {
      footerExtend: true,
      footerLight: true,
      headerDark: true,
    },
  },

  {
    path: '*',
    redirect: '/',
  },
];

/**
 * scrollBehavior
 * - only available in html5 history mode
 * - defaults to no scroll behavior
 * - return false to prevent scroll
 *
 * @param to
 * @param from
 * @param savedPosition
 * @return {Promise<unknown>|boolean|*}
 */
const scrollBehavior = function scrollBehavior(to, from, savedPosition) {
  let scrollTo = 0;

  if (to.hash) {
    scrollTo = decodeURIComponent(to.hash);
  } else if (savedPosition) {
    scrollTo = savedPosition.y;
  }

  return goTo(scrollTo);
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes,
});

export default router;
