import Vue from 'vue';
import VueSocialSharing from 'vue-social-sharing';
import vuetifyToast from '@/plugins/vuetify-toast-snackbar';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

/* Styles */
import '@/styles/overrides.scss';

import '@/filters/date';

Vue.use(VueSocialSharing);
Vue.use(vuetifyToast, {
  y: 'top',
  showClose: true,
  closeIcon: 'mdi-close',
  timeout: 4000,
  queueable: true,
  color: 'white primary--text',
  closeColor: 'black',
  shorts: {
    info: {
      border: 'left',
      borderColor: 'info',
      icon: 'mdi-information',
      iconColor: 'info',
    },
    success: {
      border: 'left',
      borderColor: 'success',
      icon: 'mdi-check-circle',
      iconColor: 'success',
    },
    error: {
      border: 'left',
      borderColor: 'error',
      icon: 'mdi-alert-circle',
      iconColor: 'error',
    },
    warning: {
      border: 'left',
      borderColor: 'warning',
      icon: 'mdi-alert-circle',
      iconColor: 'warning',
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
