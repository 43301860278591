<template>
  <v-card flat :color="color">
    <v-container>
      <div class="text-center">
        <div class="text-m-h2 text-lg-h2 mt-12">{{ title }}</div>
        <div class="text-body-3 text-lg-h6 mt-2" style="color: rgba(31, 51, 48, 0.75);">{{ subTitle }}</div>
      </div>

      <div class="product-wrapper">
        <v-card flat width="100%" tile color="transparent" :key="i" v-for="(item, i) in productItems">

          <div class="d-flex">
            <v-avatar size="36" color="secondary">
              <v-icon size="20" color="white">$star</v-icon>
            </v-avatar>
            <div class="text-h5 text-lg-h4 ml-3 primary--text">
              {{ item.title }}
            </div>
          </div>

          <div class="mt-5 text-body-3 text-lg-body-1 opacity-semi">{{ item.text }}</div>

          <div class="mt-4 font-weight-bold">

            <router-link class="link--no-decoration text-body-3 font-weight-bold primary--text" :to="{ name: `${item.to}`,hash: item.hash}">{{
                item.isContact
                  ? 'Contact us'
                  : 'Learn more'
              }}
            </router-link>
          </div>
        </v-card>
      </div>

    </v-container>
  </v-card>
</template>
<script>
export default {
  name: 'OurProductList',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    productItems: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>
<style lang="scss" scoped>
.product-wrapper {
  display: grid;
  width: 100%;
  //justify-items: center;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  //grid-column-gap: 24px;
  grid-row-gap: 48px;

  margin-top: 50px;

  @media #{map-get($display-breakpoints, 'md-and-up')}{
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 64px;
    grid-row-gap: 64px;
    margin-top: 96px;
  }
}

</style>
