<template>
  <div>
    <v-app-bar
      app
      flat
      id="toolbar"
      :absolute="!drawer"
      :color="navbarColor"
      class="header-top"
      :class="navbarClass"
      :style="navbarStyle"
      :height="$vuetify.breakpoint.lgAndUp ? '130px' : '80px'"
      clipped-left
      extension-height="false"
      :dark="!isThemeLight"
    >
      <v-container class="fill-height py-0">

        <v-row no-gutters v-if="$vuetify.breakpoint.mdAndDown" style="align-content:space-evenly" align="center" class="fill-height divider">
          <div>
            <router-link :to="{name: `home`}">
              <v-img
                :src="isThemeLight
                ? require('../../assets/img/logo/logo-light.svg')
              : require('../../assets/img/logo/logo.svg')"
              />
            </router-link>
          </div>

          <v-spacer/>

          <v-app-bar-nav-icon
            v-ripple="false"
            class="hidden-lg-and-up float-end color"
            style="height:32px;width:32px"
            @click.stop="drawer = !drawer"
          >
            <v-icon
              dense
              size="24"
              v-text="drawer ? 'mdi-close' : 'mdi-menu'"
            />

          </v-app-bar-nav-icon>

        </v-row>

        <v-row v-else no-gutters align="center" class="fill-height divider">
          <router-link :to="{name: `home`}">
            <v-img
              width="149px"
              :src="isThemeLight ?
              require('../../assets/img/logo/logo-light.svg')
              : require('../../assets/img/logo/logo.svg')"
            />
          </router-link>
          <v-spacer/>

          <v-list
            flat
            :height="isScrolling ? '91px' : null"
            color="transparent"
            class="v-list-nav v-list--gap d-flex"
          >
            <v-list-item
              :ripple="false"
              @click="show = !show"
              @mouseover="show = true"
              @focus="show = true"
              @blur="show = false"
              :class="{'v-list-item--active': isProductPage}"
            >
              <v-list-item-content>
                <v-list-item-title class="text-body-1 color" style="text-overflow:unset">
                  Products
                  <v-icon :color="isThemeLight ? 'rgba(31, 51, 48, 0.5)' : 'rgba(255, 255, 255, 0.5)'">{{
                      show
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down'
                    }}
                  </v-icon>
                  <span class="v-list-item--active__line"/>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              exact-path
              v-for="(item, i) in itemList"
              :key="i"
              :ripple="false"
              :to="{ name: `${item.to}`,hash: item.hash}"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="text-body-1 color"
                  :class="item.class"
                >
                  {{ item.title }}
                  <span class="v-list-item--active__line"/>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
      </v-container>

      <template #extension>
        <v-expand-transition>
          <v-sheet @mouseleave="show = false" @focusout="show =false" v-show="show" color="primary" width="100%">
            <v-container class="mt-6 pb-10">
              <div class="product-wrapper" >
                <v-card class="pa-8" @click="show = false" :to="{ name: `${item.to}`,hash: item.hash}" width="100%" tile color="white" :key="i" v-for="(item, i) in productNav.items">
                  <v-avatar size="36" color="secondary">
                    <v-icon size="20" color="white">$star</v-icon>
                  </v-avatar>

                  <div class="text-h5 mt-6 primary--text">{{ item.title }}</div>
                </v-card>
              </div>
            </v-container>
          </v-sheet>
        </v-expand-transition>
      </template>
    </v-app-bar>
    <expand-navbar v-if="drawer" :header-height="80">
      <v-container>
        <v-list
          flat
          dense
          color="transparent"
          class="v-list-nav v-list-nav--paddles mt-n2 mb-n3"
        >
          <template v-for="(item, i) in mobileItemList">
            <v-list-item
              v-if="item.to"
              :key="i"
              exact-path
              :ripple="false"
              :to="{ name: item.to,hash: item.hash}"
              @click.stop="drawer = !drawer"
            >
              <v-list-item-content class="my-1">
                <v-list-item-title class="text-body-3" style="line-height:24px" v-text="item.title"/>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-else
              :key="i"
              :ripple="false"
              inactive
              :input-value="isProductPage"
            >
              <v-list-item-content class="">
                <v-list-item-title disabled="" class="text-body-3 white--text" style="line-height:24px" v-text="item.title"/>

                <v-list
                  :key="i"
                  flat
                  color="transparent"
                  class="px-1 mt-n1 mb-n3"
                >
                  <v-list-item
                    v-for="(item, j) in item.items"
                    :key="`${i}-${j}`"
                    :ripple="false"
                    :to="{ name: item.to,hash: item.hash}"
                    @click.stop="drawer = !drawer"
                  >
                    <v-list-item-content style="padding-top:6px;padding-bottom:6px">
                      <v-list-item-title class="text-body-3" style="line-height:24px" v-text="item.title"/>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>

          </template>

        </v-list>

        <v-card color="rgba(255, 255, 255, 0.1);"></v-card>

      </v-container>

      <v-sheet width="100%" style="background-color:rgba(255, 255, 255, 0.1);">
        <v-container>
          <div class="text-m-h3 mt-2" style="color: rgba(255, 255, 255, 0.5);">Interested in
            <span style="color:#ffffff">Bridging Loans?</span></div>
          <div class="mt-3 white--text text-body-3">Our friendly and professional team are always happy to help with questions or inquiries you or your broker may have.</div>
          <v-btn color="accent" large width="100%" class="justify-space-between mt-8">
            Contact us
            <img style="width:32px;height:38px;" src="@/assets/img/arrow-right.svg" alt=""/>
          </v-btn>
        </v-container>
      </v-sheet>
    </expand-navbar>
  </div>
</template>

<script>

import ExpandNavbar from '@/components/ExpandNavbar.vue';

export default {
  name: 'AppNavBar',
  components: { ExpandNavbar },
  data() {
    return {
      show: false,
      drawer: false,
      isScrolling: false,
      productNav: {
        title: 'Products',
        items: [
          {
            title: 'Buy-to-let Mortgage',
            to: 'product-buy-to-let-mortgage',
          },
          {
            title: 'Residental Investments',
            to: 'product-residental-investments',
          },
          {
            title: 'Development Bridging Loans',
            to: 'product-development-bridging-loans',
          },
          {
            title: 'Commercial Bridging Loans',
            to: 'product-commercial-bridging-loans',
          },
          {
            title: 'Complex Bridging Loans',
            to: 'product-complex-bridging-loans',
          },
          {
            title: 'Bespoke solutions',
            to: 'contact-us',
          },
        ],
      },
    };
  },
  watch: {
    $route(to, from) {
      this.drawer = false;
      this.show = false;
    },
  },
  computed: {
    mobileItemList() {
      return [
        this.productNav,
        {
          title: 'About us',
          to: 'about-us',
        },
        {
          title: 'Calculate Your Loan',
          to: 'calculate-your-loan',
        },
        {
          title: 'Blog',
          to: 'blog',
        },
      ];
    },
    navbarColor() {
      if (this.drawer || this.show || this.$route?.meta?.headerDark) {
        return 'primary';
      }

      if (this.$route.meta.isHome) {
        return 'transparent';
      }

      return 'background';
    },
    navbarClass() {
      return {
        'header-drawn': this.drawer || this.show,
      };
    },
    containerClass() {
      return {
        'py-7': this.drawer && this.$vuetify.breakpoint.mdAndDown && !this.isScrolling,
        'py-4': this.drawer || (this.$vuetify.breakpoint.mdAndDown && this.isScrolling),
        'py-2': this.$vuetify.breakpoint.lgAndUp,
      };
    },
    navbarStyle() {
      return {};
    },
    theme() {
      if (this.drawer || this.show) {
        return 'dark';
      }

      if (this.$route.meta.isHome || this.$route?.meta?.headerDark) {
        return 'dark';
      }

      return 'light';
    },
    isThemeLight() {
      return this.theme === 'light';
    },
    itemList() {
      return [
        {
          title: 'About us',
          to: 'about-us',
        },
        {
          title: 'Calculate Your Loan',
          to: 'calculate-your-loan',
        },
        {
          title: 'Blog',
          to: 'blog',
        },
        {
          title: 'Contact Us',
          to: 'contact-us',
          class: this.contactClass,
        },
      ];
    },
    contactClass() {
      if (this.drawer || this.show) {
        return 'font-weight-bold color--chestnut';
      }

      if (this.$route.meta.isHome) {
        return 'font-weight-bold';
      }

      return 'font-weight-bold color--chestnut';
    },
    isProductPage() {
      return this.$route.path.startsWith('/product/');
    },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>

.theme--light {
  .divider {
    border-bottom: 1px solid rgba(31, 51, 48, 0.1);
  }
  .color {
    color: var(--v-primary-base);
  }
}

.theme--dark,
.header-drawn {
  .divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
  }
  .color {
    color: white !important;
  }
}

.theme--light {
  .color--chestnut {
    color: var(--v-chestnut-base) !important;
  }
}
.theme--dark {
  .color--chestnut {
    color: var(--v-secondary-base) !important;
  }
}

.product-wrapper {
  display: grid;
  width: 100%;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.header-top {
  ::v-deep .v-toolbar__content {
    padding-top: 0px;
    padding-bottom: 0px;
    //height: 40px !important;
    //padding-top: 12px;
    //padding-bottom: 12px;
    //margin-top: 32px;
    //
    //div {
    //  margin-top: 64px;
    //}
    padding: 0;
    //padding-left:20px;
    //padding-right:20px;
  }

  ::v-deep .v-toolbar__extension {
    padding: 0;
  }

}

//top: 28px !important;
//
//@media #{map-get($display-breakpoints, 'md-and-up')}{
//  top: 40px !important;
//}

.v-list-nav {
  .v-list-item-title--mobile {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }

  .v-list-item {
    min-height: unset !important;
    opacity: 1;
    //opacity: 0.5;
    color: white !important;

    .v-list-item__title {
      font-weight: 400;
    }

    &:hover {
      color: #ffffff !important;
    }

    &--active {
      color: #ffffff !important;
      //.v-list-item__title {
      //  font-weight: 700;
      //}
    }
  }

  .v-list-item--active {
    //border-bottom: 1px solid red;

    .v-list-item--active__line {
      //left:0;
      width: calc(100% - 32px);
      height: 1px;
      background-color: #AC806D;
      position: absolute;
      top: 89px;
      display: block !important;
    }
  }

  &--light {
    .v-list-item {
      color: var(--v-grey-base) !important;

      &:hover {
        color: #29303b !important;
      }

      &--active {
        color: #29303b !important;
      }
    }

    .v-list-item--active {
      .v-list-item--active__line {
        background-color: #0000ee;
      }
    }
  }

  &--paddles {
    padding-left: 0;
    padding-right: 0;

    > .v-list-item {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.header-top-scrolling {
  ::v-deep .v-toolbar__content {
    //padding-top:16px;
    //padding-bottom:16px;
    //height: 32px !important;
    //padding-top: 0px;
    //padding-bottom: 0px;
    //margin-top: 20px;

    //div {
    //  margin-top: 64px;
    //}
  }
}
</style>
