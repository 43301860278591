<template>
  <v-app>
    <app-nav-bar />
    <v-main>
      <slot />
    </v-main>
    <app-footer/>
  </v-app>
</template>

<script>
/* Local modules */
import AppNavBar from '@/components/App/AppNavBar.vue';
import AppFooter from '@/components/App/AppFooter.vue';

export default {
  name: 'DefaultLayout',
  components: { AppFooter, AppNavBar },
};
</script>
<style lang="scss" scoped>
//.main-top {
//  margin-top: 32px;
//
//  @media #{map-get($display-breakpoints, 'md-and-up')}{
//    margin-top: 75px;
//  }
//}
</style>
