<template>
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8168 22.1333H15.9668V34.1333C15.9668 36.9333 17.4835 37.5 19.3335 35.4L31.9502 21.0667C33.5002 19.3167 32.8502 17.8667 30.5002 17.8667H25.3502V5.86667C25.3502 3.06667 23.8335 2.5 21.9835 4.6L9.36684 18.9333C7.8335 20.7 8.4835 22.1333 10.8168 22.1333Z" stroke="#1F3330" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'FlashIcon',
};
</script>
