<script>
import VSnackbar from 'vuetify/es5/components/VSnackbar/VSnackbar';
import VSheet from 'vuetify/es5/components/VSheet/VSheet';

export default {
  name: 'VSnackbarCustom',
  extends: VSnackbar,
  props: {
    // eslint-disable-next-line vue/require-default-prop
    border: {
      type: String,
      validator(val) {
        return [
          'top',
          'right',
          'bottom',
          'left',
          '',
        ].includes(val);
      },
    },
    // eslint-disable-next-line vue/require-default-prop
    borderColor: String,
  },
  computed: {
    classes() {
      return {
        ...VSnackbar.options.computed.classes.call(this),
        'v-snack--border': Boolean(this.border),
        [`v-snack--border-${this.border}`]: Boolean(this.border),
      };
    },
  },
  methods: {
    genWrapper() {
      const setColor = this.hasBackground
        ? this.setBackgroundColor
        : this.setTextColor;
      const data = setColor(this.color, {
        staticClass: 'v-snack__wrapper',
        class: VSheet.options.computed.classes.call(this),
        style: VSheet.options.computed.styles.call(this),
        directives: [{
          name: 'show',
          value: this.isActive,
        }],
      });
      const children = [
        this.genContent(),
      ];

      if (this.border) {
        let snackBarBorder = {
          staticClass: 'v-snack__border',
          class: {
            [`v-snack__border--${this.border}`]: true,
          },
        };

        if (this.borderColor) {
          snackBarBorder = this.setBackgroundColor(this.borderColor, snackBarBorder);
        }

        children.push(this.$createElement('div', snackBarBorder));
      }

      children.push(this.genActions());

      return this.$createElement('div', data, children);
    },
  },
  render(h) {
    return h('transition', {
      attrs: { name: 'v-snack-transition' },
    }, [
      this.isActive && h('div', {
        staticClass: 'v-snack',
        class: this.classes,
        on: this.$listeners,
      }, [this.genWrapper()]),
    ]);
  },
};
</script>

<style lang="scss">
  .v-snack__wrapper {
    position: relative;
  }

  .v-snack__border {
    border-style: solid;
    /*@todo Replace value with variable*/
    /*$alert-border-width*/
    border-width: 4px;
    content: '';
    position: absolute;

    &--left, &--right {
      bottom: 0;
      top: 0;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }

    &--bottom, &--top {
      left: 0;
      right: 0;
    }

    &--bottom {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      bottom: 0;
    }

    &--left {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      left: 0;
    }

    &--right {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
      right: 0;
    }

    &--top {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      top: 0;
    }

  }
</style>
