<template>
  <v-sheet :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'">
    <v-container>
      <v-sheet color="white">
        <v-row :no-gutters="$vuetify.breakpoint.mdAndDown">
          <v-col class="py-0" cols="12" lg="6" order-lg="last">
            <div style="position:relative;height:100%">
              <v-img height="100%" src="@/assets/img/meet.jpeg">
                <v-img
                  src="@/assets/img/meet-lines.svg"
                  style="position:absolute;mix-blend-mode:soft-light;bottom:24px"
                />
              </v-img>
            </div>
          </v-col>
          <v-col class="py-0" cols="12" lg="6">
            <div class="interested-in-bridging-loans-card">
              <div class="interested-in-bridging-loans-card__content">
                <v-icon :size="$vuetify.breakpoint.lgAndUp ? 52 : 42" color="#A6B5B2">$star</v-icon>
                <div class="text-m-h2 text-lg-h2 mt-4">
                  <span style="color:#1f333080">Interested in</span> Bridging Loans?
                </div>
                <div class="text-body-3 text-lg-body-1 mt-6 opacity-semi">Our friendly and professional team are always happy to help with questions or inquiries you or your broker may have.</div>
              </div>
              <div class="interested-in-bridging-loans-card__actions">
                <v-btn to="/contact-us" style="z-index:1" color="secondary" :large="!$vuetify.breakpoint.xlOnly" :x-large="$vuetify.breakpoint.xlOnly" width="100%" class="v-btn--px-102 justify-space-between mt-8 mt-lg-16">
                        <span class="font-weight-bold">
                        Contact us
                        </span>
                  <img class="mr-xl-n6 arrow--size" src="@/assets/img/arrow-right.svg" alt=""/>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: 'InterestedInBridgingLoans',
};
</script>

<style lang="scss" scoped>
.interested-in-bridging-loans-container {
  background-color: white;
}

.interested-in-bridging-loans-card {
  margin-top: 32px;

  @media #{map-get($display-breakpoints, 'lg-and-up')}{
    padding-top: 78px;
  }

  &__content {
    @media #{map-get($display-breakpoints, 'lg-and-up')}{
      padding-left: 102px;
      padding-right: 70px;
    }
  }

  &__actions {
    margin-bottom: -48px;
    @media #{map-get($display-breakpoints, 'lg-and-up')}{
      margin-right: -32px;
      margin-bottom: 0;
    }
  }
}
</style>
