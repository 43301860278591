import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

// region Icons
import Facebook from '@/components/Icons/Facebook.vue';
import Linkedin from '@/components/Icons/Linkedin.vue';
import Twitter from '@/components/Icons/Twitter.vue';
import Link from '@/components/Icons/Link.vue';
import StarIcon from '@/components/Icons/Star.vue';
import CubeIcon from '@/components/Icons/Cube.vue';
import FlashIcon from '@/components/Icons/Flash.vue';
import ShareIcon from '@/components/Icons/Share.vue';
import ArrowUpIcon from '@/components/Icons/ArrowUp.vue';
// endregion

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 576,
      sm: 768,
      md: 1008,
      lg: 1240,
    },
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        primary: '#1F3330',
        secondary: '#AC806D',
        accent: '#AC806D',
        chestnut: '#8B4C33',
        background: '#F6F6F7',
      },
      dark: {
        green: '#34A853',
        red: '#FF6E6E',
        secondary: '#1d232f',
        third: '#262d38',
        fourth: '#29303B',
        background: '#161c28',
        accent: '#0000EE',
        grey: '#7F8A9C',
      },
    },
  },
  icons: {
    values: {
      star: {
        component: StarIcon,
      },
      cube: {
        component: CubeIcon,
      },
      flash: {
        component: FlashIcon,
      },
      share: {
        component: ShareIcon,
      },
      facebook: {
        component: Facebook,
      },
      twitter: {
        component: Twitter,
      },
      linkedin: {
        component: Linkedin,
      },
      link: {
        component: Link,
      },
      arrowUp: {
        component: ArrowUpIcon,
      },
    },
  },
});
