<template>
  <div>
    <hero/>
    <v-container class="lion-container">
      <v-row>
        <v-col cols="12" style="z-index:1">
          <v-icon :size="$vuetify.breakpoint.lgAndUp ? 52 : 42" color="#A6B5B2">$star</v-icon>
          <div class="text-m-h2 text-lg-h2 primary--text mt-4 mt-lg-7" style="max-width:886px">Bespoke funding packages from a London based bridging finance provider</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="3">
          <div class="lion-img-container">
            <v-img gradient="180deg, rgba(31, 51, 48, 0) 0%, rgba(31, 51, 48, 0.5) 100%" :width="$vuetify.breakpoint.xl ? '592px' : null" :height="$vuetify.breakpoint.xl ? '725px' : '414px'" src="@/assets/img/lion.jpeg"/>
          </div>

        </v-col>
        <v-col v-if="$vuetify.breakpoint.xlOnly" cols="12" xl="9" class="pb-0">
          <v-card tile flat :color="$vuetify.breakpoint.xlOnly ? '#e0e2e3' : 'transparent'" class="d-flex flex-column justify-space-between lion-card">
            <div class="lion-card__text-wrapper">
              <div class="text-m-h3 text-lg-h5 primary--text">We are experts in short-term finance solutions through bridge loan finance transactions.</div>
              <div class="text-body-3 text-lg-body-1 mt-6 mt-lg-10" style="color:rgba(41, 74, 69, 0.75);">Our experienced team offer access to the capital you need to fund an acquisition, refinance/refurbish your property, and more, all at the best terms.</div>
              <div class="text-body-3 text-lg-body-1 mt-6 mt-lg-10" style="color:rgba(41, 74, 69, 0.75);">Reach out today and discover the Assist Bridging difference.</div>
            </div>

            <v-card-actions class="lion-card__action-wrapper pa-0">
              <v-btn to="calculate-your-loan" color="primary" :large="!$vuetify.breakpoint.xlOnly" :x-large="$vuetify.breakpoint.xlOnly" width="100%" class="v-btn--px-102 justify-space-between">
                <span class="font-weight-bold">
                Calculate your loan
                </span>
                <img class="mr-xl-n6 arrow--size" src="@/assets/img/arrow-right.svg" alt=""/>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet v-if="!$vuetify.breakpoint.xlOnly" color="#e0e2e3">
      <v-container class="lion-container__detached">
        <v-row>
          <v-col>
            <v-card tile flat :color="$vuetify.breakpoint.xlOnly ? '#e0e2e3' : 'transparent'" class="mt-6 d-flex flex-column justify-space-between lion-card">
              <div class="lion-card__text-wrapper">
                <div class="text-m-h3 text-lg-h5 primary--text">We are experts in short-term finance solutions through bridge loan finance transactions.</div>
                <div class="text-body-3 text-lg-body-1 mt-6 mt-lg-10" style="color:rgba(41, 74, 69, 0.75);">Our experienced team offer access to the capital you need to fund an acquisition, refinance/refurbish your property, and more, all at the best terms.</div>
                <div class="text-body-3 text-lg-body-1 mt-6 mt-lg-10" style="color:rgba(41, 74, 69, 0.75);">Reach out today and discover the Assist Bridging difference.</div>
              </div>

              <v-card-actions class="mt-13 pa-0">
                <v-btn to="calculate-your-loan" style="z-index:1" color="primary" :large="!$vuetify.breakpoint.xlOnly" :x-large="$vuetify.breakpoint.xlOnly" width="100%" class="v-btn--px-102 justify-space-between">
                <span class="font-weight-bold">
                Calculate your loan
                </span>
                  <img class="mr-xl-n6 arrow--size" src="@/assets/img/arrow-right.svg" alt=""/>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <OurProductList class="mt-n12 pt-8 mt-xl-0 pt-xl-0" title="Our Products" sub-title="Short-term lending solutions for long-term goals." :product-items="productItems" color="background"/>

    <BridgingLoanCalculatorCard class="bridging-loan-calculator-card-margin"/>

    <v-container class="why-assist-bridging-container">
      <div class="text-m-h2 text-lg-h2">Why Assist Bridging?</div>
      <div class="why-assist-bridging-wrapper">
        <v-card flat color="transparent" :key="i" v-for="(item, i) in whyAssistBridging">
          <v-icon size="40">{{ item.icon }}</v-icon>

          <div class="mt-3 mt-lg-10 text-m-h3 text-lg-h4">{{ item.title }}</div>

          <div class="mt-3 text-body-3 text-lg-body-1 opacity-semi">{{ item.text }}</div>
        </v-card>
      </div>
    </v-container>

    <v-sheet class="latest-news-wrapper" color="primary">
      <div>
        <v-img
          src="@/assets/img/news-lines.svg"
          style="position:absolute;mix-blend-mode:soft-light;"
        />
        <v-container>
          <div class="white--text text-m-h2 text-lg-h2 latest-news-wrapper__title">
            <span style="opacity:0.5">Latest</span> News
          </div>

          <NewsGrid v-if="$vuetify.breakpoint.lgAndUp" class="mt-10" :latest-news="latestNews">
            <template #action>
              <div style="grid-column: 4 / span 3;grid-row:2">
                <v-btn to="blog" color="secondary" x-large width="100%" class="v-btn--px-102 justify-space-between mt-16 mb-n4">
                <span class="font-weight-bold">
               Read all posts
                </span>
                  <img class="mr-xl-n6 arrow--size" src="@/assets/img/arrow-right.svg" alt=""/>
                </v-btn>
              </div>
            </template>
          </NewsGrid>

          <div v-else class="mb-2 mt-8">
            <NewsCarouselGrid :latest-news="latestNews"/>

            <div>
              <v-btn to="blog" color="secondary" large width="100%" class="v-btn--px-102 justify-space-between mt-5">
                <span class="font-weight-bold">
               Read all posts
                </span>
                <img class="mr-xl-n6 arrow--size" src="@/assets/img/arrow-right.svg" alt=""/>
              </v-btn>
            </div>
          </div>

        </v-container>
      </div>

    </v-sheet>

    <div class="interested-in-bridging-loans-margin">
      <div class="partner-with-us--container-img"></div>
      <div class="partner-with-us--container">
        <v-container>
          <div class="text-center">
            <div class="text-m-h2 text-lg-h2">Partner with Us</div>
            <div class="text-body-3 mt-6 mx-auto opacity-semi text-lg-body-1" style="max-width:536px">Assist Bridging is ready to consider collaborations and investment opportunities by you or your broker.</div>
            <v-btn to="contact-us" outlined class="mt-8">Let’s work together</v-btn>
          </div>
        </v-container>
      </div>
      <InterestedInBridgingLoans style="" v-if="$vuetify.breakpoint.mdAndUp"/>
    </div>

    <InterestedInBridgingLoans v-if="$vuetify.breakpoint.smAndDown"/>
  </div>

</template>

<script>
import posts from '@/assets/posts.json';
import Hero from '@/components/Hero.vue';
import getImgUrl from '@/mixins/getImgUrl';
import InterestedInBridgingLoans from '@/components/InterestedInBridgingLoans.vue';
import BridgingLoanCalculatorCard from '@/components/BridgingLoanCalculatorCard.vue';
import OurProductList from '@/components/OurProductList.vue';
import NewsCarouselGrid from '@/components/NewsCarouselGrid.vue';
import NewsGrid from '@/components/NewsGrid.vue';

export default {
  name: 'HomeView',
  components: {
    NewsGrid,
    NewsCarouselGrid,
    OurProductList,
    BridgingLoanCalculatorCard,
    InterestedInBridgingLoans,
    Hero,
  },
  data() {
    return {
      productItems: [
        {
          title: 'Buy-to-let Mortgage',
          text: 'A convenient way to secure real estate rental properties with flexible and fair terms.',
          to: 'product-buy-to-let-mortgage',
        },
        {
          title: 'Residental Investments',
          text: 'Creating the capital space between buying, selling, or refurbishing your home.',
          to: 'product-residental-investments',
        },
        {
          title: 'Development Bridging Loans',
          text: 'Cut through timeline delays and secure the funds needed to complete your project.',
          to: 'product-development-bridging-loans',
        },
        {
          title: 'Commercial Bridging Loans',
          text: 'Excellent resources to stimulate growth, secure new space, and ensure liquidity.',
          to: 'product-commercial-bridging-loans',
        },
        {
          title: 'Complex Bridging Loans',
          text: 'Relieve the pressure of a complicated structure, personal financial difficulty, or tight completion date.',
          to: 'product-complex-bridging-loans',
        },
        {
          title: 'Bespoke solutions',
          text: 'A case-by-case solution by our professional team to solve your short-term challenges.',
          to: 'contact-us',
          isContact: true,
        },
      ],
      whyAssistBridging: [
        {
          title: 'Lending Flexibility',
          text: 'Custom financial solutions between £75k and £500k on a short-term basis [between 3-18 months] to overcome that final gap.',
          icon: '$share',
        },
        {
          title: 'Tailored to your needs',
          text: 'Custom bridging finance to suit your specific circumstances, offering borrowing power to purchase, refurbish, refinance, or secure a property.',
          icon: '$cube',
        },
        {
          title: 'No credit check',
          text: 'We consider all financial situations for each of our bridge loan solutions, including clients who do not wish for a credit check.',
          icon: '$flash',
        },
      ],
    };
  },
  computed: {
    latestNews() {
      const lastPosts = posts.slice(-3);
      return lastPosts.reverse();
    },
  },
  methods: {},
  mixins: [getImgUrl],
};
</script>
<style lang="scss">

.lion-container {
  margin-top: 16px;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    margin-top: 110px;
    margin-bottom: 260px;
  }

  .lion-img-container {
    margin-top: 8px;

    @media #{map-get($display-breakpoints, 'xl-only')}{
      position: absolute;
      z-index: 1;
      margin-top: 46px;
    }
  }

  .lion-card {
    .lion-card__text-wrapper {
      margin-top: 8px;
    }

    .lion-card__action-wrapper {
      margin-top: 50px
    }

    @media #{map-get($display-breakpoints, 'xl-only')}{
      padding-left: 282px;
      padding-top: 276px;
      margin-top: -228px;

      .lion-card__text-wrapper {
        margin-top: 0;
        padding-right: 78px;
        padding-left: 78px;

        .text-lg-body-1 {
          line-height: 30px
        }

        .text-lg-h5 {
          line-height: 36px
        }
      }

      .lion-card__action-wrapper {
        margin-top: 114px
      }
    }
  }
}

.lion-container__detached {
  padding-top: 220px;
  margin-top: -220px;
  padding-bottom: 0;
}

</style>
<style lang="scss" scoped>

.why-assist-bridging-container {
  margin-top: 48px;
  margin-bottom: 74px;

  .why-assist-bridging-wrapper {
    display: grid;
    width: 100%;
    justify-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-row-gap: 48px;

    margin-top: 40px;

    @media #{map-get($display-breakpoints, 'lg-and-up')}{
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 64px;
      grid-row-gap: 64px;
    }
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')}{
    margin-top: 160px;
    margin-bottom: 100px;
  }
}

.latest-news-wrapper {
  &__title {
    margin-top: 48px;

    @media #{map-get($display-breakpoints, 'lg-and-up')}{
      margin-top: 112px;
    }
  }
}

.ellipse {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.two-lines {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.partner-with-us--container {
  padding-top: 62px;
  padding-bottom: 62px;

  @media #{map-get($display-breakpoints, 'lg-and-up')}{
    padding-top: 202px;
    padding-bottom: 113px;
  }
}

.partner-with-us--container-img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: url('@/assets/img/bg-lines.svg') center;
  background-size: cover;
  z-index: -1;
}

.interested-in-bridging-loans-margin {
  position: relative;
  z-index: 1;

  @media #{map-get($display-breakpoints, 'md-and-up')}{
    margin-bottom: -64px;
  }

  @media #{map-get($display-breakpoints, 'xl-only')}{
    margin-bottom: -90px;
  }

}

.bridging-loan-calculator-card-margin {
  margin-top: 34px;

  @media #{map-get($display-breakpoints, 'lg-and-up')}{
    margin-top: 110px;
  }
}
</style>
