<template>
  <v-sheet :color="$vuetify.breakpoint.lgAndUp ? 'transparent' : 'primary'">
    <v-container>
      <v-sheet color="primary">

        <v-row no-gutters>
          <v-col cols="12" class="hidden-lg-and-up">
            <v-img
                contain
                max-height="318px"
                src="@/assets/img/mon-lines.png"></v-img>
          </v-col>

          <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" lg="6" class="mt-4 pr-lg-12 d-flex flex-column" style="padding-left:102px">
            <div class="text-h3 white--text" style="margin-top:66px">Bridging loan calculator</div>
            <div class="mt-4 text-body-1 white--text" style="opacity:0.75">Our easy-to-use Bridging Loan Calculator delivers instant and detailed quotes explaining interest, charges, and other costs associated with securing bridging finance solutions.</div>

            <div style="padding-top:76px">

              <v-btn to="calculate-your-loan" color="secondary" :large="!$vuetify.breakpoint.xlOnly" :x-large="$vuetify.breakpoint.xlOnly" width="calc(100% + 102px)" class="v-btn--px-102 justify-space-between mt-auto" style="margin-left:-102px;">
                <span class="font-weight-bold">
                Calculate your loan
                </span>
                <img class="mr-xl-n6 arrow--size" src="@/assets/img/arrow-right.svg" alt=""/>
              </v-btn>
            </div>

          </v-col>

          <v-col v-else cols="12" class="mt-2 mb-4">
            <div class="text-m-h2 mt-8 white--text">Bridging loan calculator</div>
            <div class="mt-3 text-body-3 white--text" style="opacity:0.75">Our easy-to-use Bridging Loan Calculator delivers instant and detailed quotes explaining interest, charges, and other costs associated with securing bridging finance solutions.</div>

            <v-btn to="/calculate-your-loan" color="secondary" large block class="v-btn--px-102 justify-space-between mt-8">
                <span class="font-weight-bold">
                Calculate your loan
                </span>
              <img class="mr-xl-n6 arrow--size" src="@/assets/img/arrow-right.svg" alt=""/>
            </v-btn>
          </v-col>

          <v-col offset-lg="1" lg="5" class="hidden-md-and-down">
            <v-img
                height="100%"
                src="@/assets/img/mon-lines.png"></v-img>
          </v-col>
        </v-row>
      </v-sheet>

    </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: 'BridgingLoanCalculatorCard',
};
</script>
<style lang="scss">



</style>
